<template>
  <div :class="[`testimonial-${type}`, `testimonial-${layout}`]">
    <picture v-if="src">
      <source
        v-for="imgType in createFormatArray(srcset)"
        :key="imgType.type"
        :type="imgType.type"
        :srcset="imgType.srcsetstring"
        sizes="80px"
      />
      <img :src="src" :alt="alt" class="image" loading="lazy" />
    </picture>
    <blockquote class="testimonial-text">
      <slot />
    </blockquote>
    <cite v-if="author" class="author">– {{ author }}</cite>
  </div>
</template>

<script>
  import createFormatArray from "~/mixins/createFormatArray"
  export default {
    mixins: [createFormatArray],
    props: {
      alt: {
        type: String,
        default: null,
      },
      author: {
        type: String,
        default: null,
      },
      src: {
        type: String,
        default: undefined,
      },
      srcset: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: "red",
        validator: (value) => {
          // The value must match one of these strings
          return ["red", "blue", "purple"].includes(value)
        },
      },
      layout: {
        type: String,
        default: "default",
        validator: (value) => {
          // The value must match one of these strings
          return ["default", "with-border"].includes(value)
        },
      },
    },
    data() {
      return {}
    },
  }
</script>

<style lang="scss" scoped>

  .testimonial-with-border {
    padding: $base-spacing * 10 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.testimonial-red {
      color: $red-shape;
    }
    &.testimonial-purple {
      color: $purple-shape;
    }
    &.testimonial-blue {
      color: $blue-shape;
    }
  }

  .image {
    float: right;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 80px;
    margin-left: $base-spacing * 2;
    margin-bottom: $base-spacing * 2;
  }

  .testimonial-text {
    max-width: $base-spacing * 200;
    margin: 0;
    margin-bottom: $space-s;

    @include type('blockquote');
    .testimonial-red & {
      color: $red-text;
    }
    .testimonial-blue & {
      color: $blue-text;
    }
    .testimonial-purple & {
      color: $purple-text;
    }
    &:after {
      content: " ";
      clear: right;
    }
  }

  .author {
    font-style: normal;
    color: $gray-text;
  }
</style>
